import { useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { MarkerClustering } from "../../../lib/static/MarkerClustering.js";
import { sampleMarkerData } from "../../../lib/data/sampleMarkerData";
import { FILTERS_BAR_HEIGHT, SEARCH_BAR_HEIGHT } from "../variables";
import palette from "../../../lib/styles/palette";
import { withOpacity } from "../../../lib/styles/utils";
import axios from "axios";

export type ClusterMapProps = {
    centerLocation: {
        lat: number;
        lng: number;
    };
};

function ClusterMap(props: ClusterMapProps) {
    const { centerLocation } = props;
    const mapRef = useRef(null);
    const mapInstance = useRef<naver.maps.Map | null>(null);
    // markerData의 타입을 명시적으로 정의
    type MarkerData = {
        lat: number;
        lng: number;
    };

    const [markerData, setMarkerData] = useState<MarkerData[]>([]); // 타입 지정
    const [isLoading, setIsLoading] = useState(false); // 로딩 상태
    const [error, setError] = useState<string | null>(null); // 에러 상태


    // 데이터를 서버에서 가져오기
    const fetchMarkerData = async () => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_HOST}/program/nearby/markers`
            );
            setMarkerData(response.data); // 서버에서 받아온 마커 데이터
        } catch (err) {
            console.error("Error fetching marker data:", err);
            setError("데이터를 불러오는 중 문제가 발생했습니다.");
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        if (mapRef.current) { // markerData가 있을 때만 실행
            const map = new naver.maps.Map(mapRef.current, {
                zoom: 15,
                center: new naver.maps.LatLng(
                    centerLocation.lat,
                    centerLocation.lng
                ),
                zoomControl: false,
            });

            const markers = []; // 마커 배열
            for (let i = 0, ii = markerData.length; i < ii; i++) {
                const spot = markerData[i]; // fetchMarkerData로 가져온 데이터 사용
                const latlng = new naver.maps.LatLng(spot.lat, spot.lng);
                const marker = new naver.maps.Marker({
                    position: latlng,
                    draggable: false, // 사용자가 마커를 드래그 가능 여부
                });

                markers.push(marker);
            }


            const htmlMarker1 = {
                    content: `<div style="${clusterStyle}"></div>`,
                    size: new naver.maps.Size(36, 36),
                    anchor: new naver.maps.Point(20, 20),
                },
                htmlMarker2 = {
                    content: `<div style="${clusterStyle}"></div>`,
                    size: new naver.maps.Size(36, 36),
                    anchor: new naver.maps.Point(20, 20),
                },
                htmlMarker3 = {
                    content: `<div style="${clusterStyle}"></div>`,
                    size: new naver.maps.Size(36, 36),
                    anchor: new naver.maps.Point(20, 20),
                },
                htmlMarker4 = {
                    content: `<div style="${clusterStyle}"></div>`,
                    size: new naver.maps.Size(36, 36),
                    anchor: new naver.maps.Point(20, 20),
                },
                htmlMarker5 = {
                    content: `<div style="${clusterStyle}"></div>`,
                    size: new naver.maps.Size(36, 36),
                    anchor: new naver.maps.Point(20, 20),
                };

            new MarkerClustering({
                minClusterSize: 2,
                maxZoom: 13,
                map: map,
                markers: markers,
                disableClickZoom: false,
                gridSize: 120,
                icons: [
                    htmlMarker1,
                    htmlMarker2,
                    htmlMarker3,
                    htmlMarker4,
                    htmlMarker5,
                ],
                indexGenerator: [10, 100, 200, 500, 1000],
                stylingFunction: (clusterMarker: any, count: any) => {
                    clusterMarker
                        .getElement()
                        .querySelector("div:first-child").innerText = count;
                },
            });

            mapInstance.current = map;
        }
    }, [markerData]);

    useEffect(() => {
        if (mapInstance.current) {
            const targetLocation = new naver.maps.LatLng(
                centerLocation.lat,
                centerLocation.lng
            );
            mapInstance.current.setCenter(targetLocation);
            mapInstance.current.setZoom(15);
        }
    }, [centerLocation.lat, centerLocation.lng]);

    useEffect(() => {
        fetchMarkerData(); // 컴포넌트가 마운트되면 데이터 가져오기
    }, []);


    return <MapCanvas ref={mapRef} />;
}

const MapCanvas = styled.div`
    width: 100%;
    height: calc(100vh - ${FILTERS_BAR_HEIGHT} - ${SEARCH_BAR_HEIGHT});
`;

const clusterStyle = `
    width: 36px;
    height: 36px;
    line-height: 38px;
    font-size: 15px;
    font-weight: 700;
    color: ${palette.white0};
    text-align: center;
    cursor: pointer;
    background-color: ${palette.red500}${withOpacity(0.64)};
    border-radius: 50%;
`;

export default ClusterMap;
