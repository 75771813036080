import BottomActionBar from "../components/courseDetail/BottomActionBar";
import DescriptionSection from "../components/courseDetail/DescriptionSection";
import MapSection from "../components/courseDetail/MapSection";
import ReviewSection from "../components/courseDetail/ReviewSection";
import TagsSection from "../components/courseDetail/TagSection";
import OptionModal from "../components/courseDetail/OptionModal";
import useToggle from "../lib/hooks/useToggle";
import PolicySection from "../components/courseDetail/PolicySection";
import { copyToClipboard } from "../lib/utils";
import Banner from "../components/courseDetail/Banner";
import HeaderSection from "../components/courseDetail/HeaderSection";
import PageTemplate from "../components/basics/PageTemplate";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

import {toast} from "react-toastify";

function CourseDetail() {
    const [optionModalOpened, toggleOptionModalOpened] = useToggle(false);
    const params = useParams();
    const [courseDetail, setCourseDetail] = useState({
        id: 10,
        thumbnail: "",
        banners: Array(4).fill(""),
        title: "밍글무드",
        region: "부산",
        discountRate: 15,
        price: 50000,
        liked: false,
        likes: 281,
        content:"",
        hostInfo: {
            profileImg: "",
            nickname: "밍글무드",
            courseCnt: 5,
            reviewCnt: 13,
            likes: 28,
        },
        rating: 4.8,
        reviewCnt: 0,
        reviews: [
            {
                photo: "",
                imgUrl: "",
                nickname: "밍글무드",
                content: "",
            }
        ],
        placeName: "밍글무드",
        address: "서울 종로구",
        lat: 37.3595704,
        lng: 127.105399,
        optionGroups: [{
            name: "성별",
            options: [
                {
                    id: 1,
                    name: "[부산] 남 (정가)",
                    price: 49000,
                    count: 12,
                }
            ]
            },
        ],
    });

    const handleCourseDetail = async () => {
        await new Promise((r) => setTimeout(r, 1000));
        const token = localStorage.getItem('login-token');

        const response = await fetch(
            process.env.REACT_APP_HOST + "/program/course?id=" + params.id,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            }
        );

        if (response.status === 200) {
            const result = await response.json();
            console.log(result);
            // 서버로부터 liked 값을 받아 상태 갱신
            setCourseDetail((prev) => ({
                ...prev,
                ...result,
            }));

            //setContent(result); // 유저 정보 상태에 저장
        } else {
            alert("알수 없는 오류, 고객센터에 문의 주시기 바랍니다.");
        }

    };

    const updateLikeStatus = async (courseId: number, liked: boolean) => {
        try {
            const token = localStorage.getItem("login-token");
            const response = await fetch(
                `${process.env.REACT_APP_HOST}/favorite/course/${courseId}/like`, // 좋아요 관련 API URL 경로
                {
                    method: liked ? "POST" : "DELETE", // 좋아요 추가는 POST, 좋아요 취소는 DELETE
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // 성공적으로 상태 갱신
            if (!response.ok) {
                throw new Error("좋아요 업데이트 실패");
            }

            return { success: true };
        } catch (error) {
            console.error("Error updating like status:", error);
            return { success: false };
        }
    };

    useEffect(() => {
        handleCourseDetail(); // 컴포넌트가 마운트될 때 유저 정보 불러오기
    }, []);


    const {
        id,
        thumbnail,
        banners,
        title,
        region,
        discountRate,
        price,
        liked,
        hostInfo,
        rating,
        reviewCnt,
        reviews,
        content,
        lat,
        lng,
        address,
        placeName,
        optionGroups,
    } = courseDetail;


    const submitData = {
        id,
        thumbnail,
        region,
        title,
        rating,
        reviewCnt,
    };

    // 자식에서 좋아요 변경 요청 처리
    const onLikeChange = async (updatedLiked: boolean) => {
        const result = await updateLikeStatus(courseDetail.id, updatedLiked);
        if (result.success) {
            // 통신 성공 시 liked 상태 업데이트
            setCourseDetail((prev) => ({
                ...prev,
                liked: updatedLiked,
            }));
        } else {
            // 실패 시 알림 추가
            alert("좋아요 상태를 업데이트하지 못했습니다.");
        }
    };

    const onClickApply = () => {
        toggleOptionModalOpened();
    };

    const handleShare = () => {
        const url = document.location.href;
        copyToClipboard(url);
    };

    return (
        <PageTemplate gnbVisible>
            <Banner onClickShare={handleShare} banners={banners} />
            <HeaderSection
                title={title}
                region={region}
                discountRate={discountRate}
                price={price}
                hostInfo={hostInfo}
            />
            <ReviewSection reviewCnt={reviewCnt} reviews={reviews} />
            <DescriptionSection courseInfo={content} />
            <TagsSection tags={[]} />
            <MapSection
                lat={lat}
                lng={lng}
                name={placeName}
                address={address}
            />
            {optionGroups.map((group, index) => (
                <OptionModal
                    key={index}
                    submitData={submitData}
                    optionName={group.name}
                    optionData={group.options}
                    visible={optionModalOpened}
                    handleClose={toggleOptionModalOpened}
                />
            ))}
            <PolicySection />
            <BottomActionBar
                isLiked={liked} // 현재 "좋아요" 상태 전달
                onLikeChange={onLikeChange} // 좋아요 변경 콜백 전달
                onClickApply={onClickApply}
                onClickShare={handleShare}
            />
        </PageTemplate>
    );
}

export default CourseDetail;


